// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverUrl: "http://dev.petrocenter.com.bo",
  firebase: {
    apiKey: "AIzaSyA0B2k7kN1ndsdRIG-DMBVD7JqOpX5z59s",
    authDomain: "dev.petrocenter.com.bo",
    databaseURL: "https://petrodev.firebaseio.com",
    projectId: "petrodev",
    storageBucket: "petrodev.appspot.com",
    messagingSenderId: "474215057635",
    appId: "1:474215057635:web:abc8102c451cfa7cdaddd6"
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
